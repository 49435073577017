<template>
  <BaseModal
    dataClass="gantt-regend-modal"
    size="mg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:main>
      <b-table
        class="table table-bordered detail-table"
        bordered
        hover
        no-border-collapse
        responsive
        selectable
        small
        sticky-header
        outlined
        selectMode="single"
        :items="regendItems"
        :fields="fieldInfo"
      >
        <template v-slot:cell(column_icon)="row">
          <div
            v-bind:style="bgColor(row.item.column_icon)"
            class="odinariColor"
          ></div>
        </template>
      </b-table>
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- 閉じるボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.close"
        @click="cancel"
      />
    </template>
  </BaseModal>
</template>

<script>
import TooltipInfo from '@/assets/json/GanttRegendInfo.json'
export default {
  name: 'ganttRegendModal',
  data() {
    return {
      regendItems: [],
    }
  },
  created() {
    this.regendItems = this.getRegendItems
  },
  computed: {
    //JSON ファイル表示
    getRegendItems() {
      let array = JSON.parse(JSON.stringify(TooltipInfo.resultList))
      console.log(array)
      return array
    },

    fieldInfo() {
      return this.$store.getters['init/getFields'](this.SCREEN_ID.GANTT_VIEW)(
        'ordinary_field'
      )
    },

    resultControlInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'calendar'
      )
    },
  },
  methods: {
    bgColor(data) {
      let newColor = 'background-color:' + data
      newColor = newColor + '; border: 1px solid #ccc'
      return newColor
    },
  },
}
</script>

<style lang="scss" scoped>
.odinariColor {
  width: 50px;
  height: 22px;
  background-color: #ffffff;
}
</style>
